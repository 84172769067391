<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="list">
        <div class="item" v-for="item in news" :key="item.id" @click="handleClick(item)" v-show="activeTag === 0 || activeTag === item.id">
          <div class="cover">
            <img :src="`${OSS_URL}/${item.pictureVertical}`" alt="" />
          </div>
        </div>
      </div>
    </div>
    <menu-pop :activeItem="1"></menu-pop>
    <back :pageDepth="2"></back>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import navRouter from '@/components/navRouter'
import back from '@/components/back'
import { API_MODULE } from '@/request/api'
import { openUrl } from '@/utils/windowOpen'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
const blueBg = require('@/assets/images/index/bg-blue.png')
const redBg = require('@/assets/images/index/bg.png')
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'THE_CURRENT_POLITICS_NEWS',
  components: {
    menuPop,
    navRouter,
    back
  },
  props: {
    appid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeTag: 0,
      icon: redIcon,
      bg: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgRed: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgBlue: {
        backgroundImage: 'url(' + blueBg + ')'
      },
      news: []
    }
  },

  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.bg = this.bgRed
    } else {
      this.icon = blueIcon
      this.bg = this.bgBlue
    }
    this.getData()
    // 保存路径
    this.setRouterList([{ name: '时政要闻', path: this.$route.fullPath }])
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
      }
    }
  },

  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getData() {
      this.GET(API_MODULE.SEARCH_CATEGORY_OR_CONTENT, { appId: this.appid })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.news = res.data.model
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleClick(model) {
      if (model.externalLinksUrl) {
        openUrl(model.externalLinksUrl)
      } else {
        this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
          catId: [model.id]
        })
          .then((res) => {
            if (res.code == 0 || res.code == 200) {
              this.$router.push({
                path: `/layout/customize/${res.data.type}`,
                query: {
                  id: model.id
                }
              })
              // music: model.music,

              // const path = `/layout/customize/${res.data.type}?id=${model.id}#music=${model.music}`
              const path = `/layout/customize/${res.data.type}?id=${model.id}`
              this.useRouterList.push({
                name: model.name,
                path: path
              })
              this.setRouterList(this.useRouterList, path)
            } else {
              this.$message(res.msg)
            }
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  min-height: 25.6rem;
  box-sizing: border-box;
  .router-nav {
    width: 100%;
    height: 0.6rem;
    background-color: #ffffff;
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .nav-left {
      display: flex;

      .router-name {
        font-size: 0.16rem;
        @include font_color('font-medium');
        margin-left: 0.1rem;
      }
    }
  }

  .content {
    /*min-height: calc(100vh - 1.4rem);*/
    min-height: 24.2rem;
    padding: 0.4rem 0 0 0.4rem;
    box-sizing: border-box;
    // @include background_color("background-medium");
    .list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 6.6rem;
        height: 4.5rem;
        overflow: hidden;
        position: relative;
        margin-right: 0.4rem;
        margin-bottom: 0.4rem;
        cursor: pointer;

        .cover {
          height: 100%;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
