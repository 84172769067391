<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="search">
        <div style="display: flex" v-if="showSearch">
          <input type="text" placeholder="搜索红色基地" class="search-input" />
          <div class="search-btn" @click="handleSearch">
            <span>搜索</span>
          </div>
        </div>
      </div>
      <div class="left_map" id="left_map"></div>
    </div>

    <!-- <div class="back" v-if="showSearch" @click="showChinaMap">
      <img src="@/assets/images/index/back-gray.png" alt="" />
      <p>还原地图</p>
    </div> -->
    <!-- <back :pageDepth="2"></back> -->
    <div class="back-group">
      <div class="btn-group">
        <div v-if="showMore" @click="handleBack">
          <img src="@/assets/images/index/back-gray.png" alt="" />
        </div>
        <div v-if="showMore" @click="showMore = !showMore">
          <img src="@/assets/images/index/close-gray.png" alt="" />
        </div>
        <div v-if="!showMore" @click="showMore = !showMore">
          <img src="@/assets/images/index/open-gray.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import china from "echarts/map/json/china.json";
const china = require('../../../public/province/china.json')
import echarts from "echarts";
import axios from "axios";
import navRouter from "@/components/navRouter";
import mark from "@/assets/images/base/mark.png";

const redIcon = require("@/assets/images/dangHistory/home.png");
const blueIcon = require("@/assets/images/dangHistory/home-blue.png");

const blueBg = require("@/assets/images/base/bg-blue.png");
const redBg = require("@/assets/images/base/bg.png");
import { mapGetters, mapMutations } from "vuex";
echarts.registerMap("china", china);
var provinces = ["shanghai", "hebei", "shanxi", "neimenggu", "liaoning", "jilin", "heilongjiang", "jiangsu", "zhejiang", "anhui", "fujian", "jiangxi", "shandong", "henan", "hubei", "hunan", "guangdong", "guangxi", "hainan", "sichuan", "guizhou", "yunnan", "xizang", "shanxi1", "gansu", "qinghai", "ningxia", "xinjiang", "beijing", "tianjin", "chongqing", "xianggang", "aomen", "taiwan"];
var provincesText = ["上海", "河北", "山西", "内蒙古", "辽宁", "吉林", "黑龙江", "江苏", "浙江", "安徽", "福建", "江西", "山东", "河南", "湖北", "湖南", "广东", "广西", "海南", "四川", "贵州", "云南", "西藏", "陕西", "甘肃", "青海", "宁夏", "新疆", "北京", "天津", "重庆", "香港", "澳门", "台湾"];
export default {
  name: "RED_BASE",
  inject: ['reload'],
  components: {
    navRouter,
  },
  props: {
    appid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      map: {},
      icon: redIcon,
      bg: {
        backgroundImage: "url(" + redBg + ")"
      },
      bgRed: {
        backgroundImage: "url(" + redBg + ")"
      },
      bgBlue: {
        backgroundImage: "url(" + blueBg + ")"
      },
      province: null,//当前展开的省份数据
      showSearch: false,
      resultData: [
        { city: "山东", pepole: 2400 },
        { city: "福建", pepole: 2400 },
        { city: "河北", pepole: 2400 },
        { city: "河南", pepole: 2400 },
        { city: "重庆", pepole: 2400 },
        { city: "湖北", pepole: 2400 },
        { city: "湖南", pepole: 2400 },
        { city: "香港", pepole: 2400 },
        { city: "澳门", pepole: 2400 },
        { city: "台湾", pepole: 2400 },
        { city: "江西", pepole: 2400 },
        { city: "海南", pepole: 2400 },
        { city: "黑龙江", pepole: 2400 },
        { city: "天津", pepole: 2400 },
        { city: "贵州", pepole: 2400 },
        { city: "陕西", pepole: 2400 },
        { city: "新疆", pepole: 2400 },
        { city: "江苏", pepole: 2400 },
        { city: "安徽", pepole: 2400 },
        { city: "西藏", pepole: 2400 },
        { city: "吉林", pepole: 2400 },
        { city: "上海", pepole: 2400 },
        { city: "山西", pepole: 2400 },
        { city: "甘肃", pepole: 2400 },
        { city: "宁夏", pepole: 2400 },
        { city: "四川", pepole: 2400 },
        { city: "浙江", pepole: 2400 },
        { city: "广西", pepole: 2400 },
        { city: "云南", pepole: 2400 },
        { city: "内蒙古", pepole: 2400 },
        { city: "辽宁", pepole: 2400 },
        { city: "广东", pepole: 2400 },
        { city: "青海", pepole: 2400 },
        { city: "北京", pepole: 2400 },],
      seriesData: [],
      max: 12000,
      marks: [],//覆盖物经纬度数组
      provinceText: '',
      searchContent: '',
      showMore: false
    };
  },
  computed: {
    ...mapGetters("skin", ["useSubject", "useRouterList", "useProvince"])
  },
  created() {
    if (this.useSubject === "red") {
      this.icon = redIcon;
      this.bg = this.bgRed;
    } else {
      this.icon = blueIcon;
      this.bg = this.bgBlue;
    }
  },
  watch: {
    useSubject(val) {
      if (val === "red") {
        this.icon = redIcon;
        this.bg = this.bgRed;
      } else {
        this.icon = blueIcon;
        this.bg = this.bgBlue;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap();
    });
    this.resultData.forEach((item) => {
      this.seriesData.push({
        name: item.city,
        value: (item.pepole)
      });
    });
    this.handleProvince()
  },
  methods: {
    ...mapMutations("skin", ["setRouterList"]),
    getMapOpt(place, cityData) {
      let option = {
        // backgroundColor: "#F3F3F3", //地图背景颜色
        //以下是地图标题，我在此处设空，需要的可以自己加上
        title: {
          text: "",
          subtext: "",
          left: "center"
        },
        //以下是地图右侧“还原”“下载”工具框
        toolbox: {
          show: false,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            restore: {},
            saveAsImage: {}
          }
        },
        visualMap: {//visualMap 是视觉映射组件，用于进行『视觉编码』，也就是将数据映射到视觉元素（视觉通道）。
          show: false,//是否显示组件
          max: this.max,//指定 visualMapContinuous 组件的允许的最大值
          left: "5%",
          top: "bottom",
          bottom: "5%",
          text: ["高", "低"],//两端的文本，如 ['High', 'Low']
          calculable: false,//是否显示拖拽用的手柄（手柄能拖拽调整选中范围）。
          inRange: {//定义 在选中范围中 的视觉元素。
            color: ["#f8d7d7", "#E03838", "#C92727", "#B40000", "#EE162D", "#EE0012", "#950A0A"]//图元的颜色。
          },
          textStyle: {//设置文字颜色（Demo左下组件）950A0A
            color: "#eee"
          }
        },
        geo: {
          map: place || "china",
          label: {
            emphasis: {
              show: false
            },
            // 不需要显示地名可直接删除normal此项
            normal: {
              show: true, // 是否显示对应地名
              textStyle: {
                color: "#000000"
              }
            }
          },
          zoom: 1.2,
          // center: [115, 36], // 调整地图位置
          roam: false,
          itemStyle: {
            normal: {
              areaColor: "#E32626", //地图颜色
              borderColor: "#fff" //地图边线颜色
            },
            emphasis: {
              areaColor: "#B7DFED" //鼠标移入颜色
            }
          }
        },
        series: [
          {
            name: "",
            type: "map",
            mapType: "china",
            geoIndex: 0,//理解就是多个组件都能用option里配置的geo地图，类似于坐标轴里的yAxisIndex:numder,也就是说你可以配置多个geo，也可以多个组件共用geo
            label: {
              normal: {
                show: true
              },
              emphasis: {
                show: true
              }
            },
            // data: this.seriesData
            data: cityData ? cityData : this.seriesData
          },
          {
            type: "scatter",
            coordinateSystem: "geo",
            data: this.marks,
            symbolSize: [30, 30],
            symbol: "image://" + mark,
            symbolColor: "#000000"
          }
        ]
      };
      return option;
    },
    // 显示中国地图
    showChinaMap() {
      this.marks = [];//返回时清空标记点
      const option = this.getMapOpt();
      this.map.setOption(option, true);
      this.showSearch = false; //隐藏搜索
    },
    // 显示各省地图，这里使用axios请求本地文件，provice文件夹存在的位置为public/province（旧版本是static）
    getProvinceMapOpt(provinceAlphabet) {
      axios.get("map/json/province/" + provinceAlphabet + ".json").then((s) => {
        const provinceIndex = provinces.findIndex((x) => {
          return provinceAlphabet === x;
        });
        this.provinceText = provincesText[provinceIndex];
        echarts.registerMap(provinceAlphabet, s.data);

        //此处为从本地获取的模拟数据，所有value均为3000，实际请从服务器获取
        let cityData = [];
        s.data.features.forEach(item => {
          cityData.push({
            name: item.properties.name,
            value: 3000
          });
        });

        //设置需要跳转的市级需要标记的点的经纬度 实际数据请从后台获取替换
        this.marks = [
          {
            name: "岳麓区",
            value: [112.961915, 28.24031]
          },
          {
            name: "天心区",
            value: [112.9936, 28.119494]
          }
          // value的前两项是经纬度坐标
        ];
        const option = this.getMapOpt(provinceAlphabet, cityData); //在这之前根据省id从后台拿到市的数据信息 在这个方法处传入市级数据 见上方数据
        this.province = s.data.features;
        this.map.setOption(option, true);
        this.showSearch = true;

        const path = `/layout/app/RED_BASE/11?province=${provinceAlphabet}`
        if (!this.useRouterList.find((router) => router.path == path)) {
          this.useRouterList.push({
            name: this.provinceText + '省',
            path: path
          })
          this.setRouterList(this.useRouterList, path)
          this.$router.push({ path: path })
          this.$store.commit('skin/setProvince', provinceAlphabet)
        }
      });
    },
    initMap() {
      var dom = document.getElementById("left_map");
      this.map = echarts.init(dom);
      const option = this.getMapOpt();
      if (option && typeof option === "object") {
        this.map.setOption(option, true);
      }
      this.map.on("click", (param) => {
        event.stopPropagation();// 阻止冒泡
        // 找到省份名
        const provinceIndex = provincesText.findIndex(x => {
          return param.name === x;
        });
        //在这里判断provincesText中是否包含点击的省份名，有则渲染省级地图，无则无处理
        if (provinceIndex === -1) {
          this.province.forEach(item => {
            if (item.properties.name === param.name) {

              this.GET(this.API_MODULE.SEARCH_MAP_BY_APP_AND_AREA, {
                appId: this.appid,
                city: param.name
              }).then((res) => {
                if (res.code == 0 || res.code == 200) {
                  if (res.data.model.length >= 0) {
                    //  在此处拿到对应市区的编码和名称跳转至下一页
                    this.$router.push({
                      path: "/layout/baseList",
                      query: {
                        area: param.name,
                        appid: this.appid,
                        method: 'normal'
                      },
                    });
                    const path = `/layout/baseList?area=${param.name}&appid=${this.appid}&method=normal`
                    this.useRouterList.push({
                      name: param.name,
                      path: path
                    })
                    this.setRouterList(this.useRouterList, path)
                  }
                } else {
                  this.$message(res.msg);
                }
              }).catch(err => {
                this.$message.error(err.msg);
              })
            }
          });
          return false;
        }
        const provinceAlphabet = provinces[provinceIndex];
        // 重新渲染各省份地图
        this.getProvinceMapOpt(provinceAlphabet);
      });
    },
    handleSearch() {
      this.GET(this.API_MODULE.SEARCH_MAP_BY_APP_AND_AREA, {
        appId: this.appid,
        name: this.searchContent,
        province: this.provinceText
      }).then((res) => {
        if (res.code == 0 || res.code == 200) {
          if (res.data.model.length >= 0) {
            this.$router.push({
              path: "/layout/baseList",
              query: {
                name: this.searchContent,
                province: this.provinceText,
                appid: this.appid,
                method: 'search'
              },
            });
          }
        } else {
          this.$message(res.msg);
        }
      }).catch(err => {
        this.$message.error(err.msg);
      })
    },
    handleBack() {
      if (this.useProvince) {
        this.$store.commit('skin/setProvince', '')
        this.$router.replace({ path: '/layout/app/RED_BASE/11' })
        this.reload()
        // this.handleProvince()
      } else {
        this.$router.push({ path: '/layout/index' })
      }
    },
    handleProvince() {
      if (this.useProvince) {
        this.getProvinceMapOpt(this.useProvince)
        this.useRouterList.find((router, i) => {
          if (!router) return
          if (router.path === this.$route.fullPath) {
            this.useRouterList.splice(i + 1)
          }
        })
        this.setRouterList(this.useRouterList, this.$route.fullPath)
      } else {
        // 保存路径
        this.setRouterList([{ name: '红色基地', path: this.$route.fullPath }])
      }
    }
  }
}
  ;
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";

.box {
  width: 14.4rem;
  height: 24.8rem;
  box-sizing: border-box;

  .content {
    /*display: flex;*/
    height: 12.8rem;

    .search {
      padding-top: 0.8rem;
      /*padding-left: 1.2rem;*/
      width: 100%;
      display: flex;
      justify-content: center;
      box-sizing: border-box;

      .search-input {
        width: 5.6rem;
        height: 0.6rem;
        background: #ffffff;
        border-style: solid;
        @include border_color("border-medium");
        border-width: 0.02rem;
        border-radius: 0.05rem;
        outline: none;
        padding: 0.2rem 0.4rem;
        box-sizing: border-box;

        ::-webkit-input-placeholder {
          @include font_color("font-low");
        }

        :-moz-placeholder {
          /* Firefox 18- */
          @include font_color("font-low");
        }

        ::-moz-placeholder {
          /* Firefox 19+ */
          @include font_color("font-low");
        }

        :-ms-input-placeholder {
          @include font_color("font-low");
        }
      }

      .search-btn {
        width: 1.2rem;
        height: 0.6rem;
        margin-left: 0.4rem;
        @include background_color("background-normal");
        opacity: 1;
        border-radius: 0.05rem;
        @include font_color("font-witer");
        line-height: 0.6rem;
        text-align: center;
        font-size: 0.22rem;
      }
    }

    .left_map {
      width: 100%; //此处设置地图大小
      height: calc(
        100% - 0.8rem
      ); //注意，如果父组件没有设置高度，此处可设置为px单位的高度，否则塌陷
      background: transparent;
    }
  }

  .back {
    position: fixed;
    right: 0.6rem;
    bottom: 4rem;
    @include font_color("font-medium");
    font-size: 0.24rem;
    font-weight: 600;
  }
}

.back-group {
  z-index: 3005;
  position: fixed;
  right: 0.6rem;
  bottom: 0.8rem;
  /*opacity: 0.8;*/
  .btn-group {
    div {
      margin-bottom: 0.1rem;
    }
  }
  img {
    width: 1.2rem;
  }
}
</style>
