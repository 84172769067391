<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="content-block-top">
        <div class="content-block-top-left">
          <div class="block" @click="handleClick(oneModel)">
            <img v-if="oneModel.pictureVertical!=null" :src="`${OSS_URL}${oneModel.pictureVertical}`" alt="" style="width: 13.6rem; height: 7.4rem" />
            <img v-else :src="`${xiaotouming}`" alt="" style="width: 13.6rem; height: 7.4rem" />
            <p class="block-title-1 block-title" :title="oneModel.name">
              {{ oneModel.name }}
            </p>
          </div>
        </div>
        <!--<div class="content-block-top-right">-->
        <div class="content-block-top-right-top">
          <div class="block" v-for="item in twoModels" :key="item.id" @click="handleClick(item)">
            <img v-if="item.pictureVertical!=null" :src="`${OSS_URL}${item.pictureVertical}`" alt="" style="width: 6.6rem; height: 3rem" />
            <img v-else :src="`${xiaotouming}`" alt="" style="width:  6.6rem; height: 3rem" />
            <p class="block-title-2 block-title" :title="item.name">
              {{ item.name }}
            </p>
          </div>
        </div>
        <div class="content-block-top-right-bottom">
          <div class="block" v-for="item in threeModels" :key="item.id" @click="handleClick(item)">
            <img v-if="item.pictureVertical" :src="`${OSS_URL}${item.pictureVertical}`" alt="" style="width: 3.1rem; height: 3rem" />
            <img v-else :src="`${xiaotouming}`" alt="" style="width: 3.1rem; height: 3rem" />
            <p class="block-title-4 block-title" :title="item.name">
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>
      <div class="content-block-bottom">
        <div class="content-block-bottom-left">
          <div class="block" v-for="item in fiveModels" :key="item.id" @click="handleClick(item)">
            <img v-if="item.pictureVertical" :src="`${OSS_URL}${item.pictureVertical}`" alt="" style="width: 6.6rem; height: 3rem" />
            <img v-else :src="`${xiaotouming}`" alt="" style="width: 6.6rem; height: 3rem" />
            <p class="block-title-8 block-title" :title="item.name">
              {{ item.name }}
            </p>
          </div>
        </div>
        <div class="content-block-bottom-right">
          <div class="block" v-for="item in fourModels" :key="item.id" @click="handleClick(item)">
            <img v-if="item.pictureVertical" :src="`${OSS_URL}${item.pictureVertical}`" alt="" style="width: 4.26rem; height: 3rem" />
            <img v-else :src="`${xiaotouming}`" alt="" style="width: 4.26rem; height: 3rem" />
            <p class="block-title-10 block-title" :title="item.name">
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>

      <div class="content-block-custom">
        <div class="block" v-for="item in sixModels" :key="item.id" @click="handleClick(item)">
          <img v-if="item.pictureVertical" :src="`${OSS_URL}${item.pictureVertical}`" alt="" style="width: 4.26rem; height: 3rem" />
          <img v-else :src="`${xiaotouming}`" alt="" style="width: 4.26rem; height: 3rem" />
          <p class="block-title-10 block-title" :title="item.name">
            {{ item.name }}
          </p>
        </div>
      </div>
    </div>
    <menu-pop :activeItem="0"></menu-pop>
    <back :pageDepth="2"></back>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import navRouter from '@/components/navRouter'
import back from '@/components/back'
import { openUrl } from '@/utils/windowOpen'
const xiaotouming = require('@/assets/images/xiaotouming.png')
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
const blueBg = require('@/assets/images/index/bg-blue.png')
const redBg = require('@/assets/images/index/bg.png')
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PARTY_BUILDING_KNOWLEDGE',
  props: {
    appid: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgRed: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgBlue: {
        backgroundImage: 'url(' + blueBg + ')'
      },
      xiaotouming: xiaotouming,
      oneModel: [],
      twoModels: [],
      threeModels: [],
      fourModels: [],
      fiveModels: [],
      sixModels: []
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getData() {
      this.GET(this.API_MODULE.SEARCH_CATEGORY_OR_CONTENT, { appId: this.appid })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.oneModel = res.data.model.slice(0, 1)[0]
            this.twoModels = res.data.model.slice(1, 3)
            this.threeModels = res.data.model.slice(3, 7)
            this.fourModels = res.data.model.slice(7, 10)
            this.fiveModels = res.data.model.slice(10, 12)
            this.sixModels = res.data.model.slice(12)
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleClick(model) {
      if (model.externalLinksUrl) {
        openUrl(model.externalLinksUrl)
      } else {
        this.POST(this.API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
          catId: [model.id]
        })
          .then((res) => {
            if (res.code == 0 || res.code == 200) {
              this.$router.push({
                path: `/layout/customize/${res.data.type}`,
                query: {
                  id: model.id
                }
              })
              // music: model.music

              // const path = `/layout/customize/${res.data.type}?id=${model.id}#music=${model.music}`
              const path = `/layout/customize/${res.data.type}?id=${model.id}`
              this.useRouterList.push({
                name: model.name,
                path: path
              })
              this.setRouterList(this.useRouterList, path)
            } else {
              this.$message(res.msg)
            }
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.bg = this.bgRed
    } else {
      this.icon = blueIcon
      this.bg = this.bgBlue
    }
    this.getData()

    this.setRouterList([{ name: '党建知识', path: this.$route.fullPath }])
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
      }
    }
  },
  components: {
    menuPop,
    navRouter,
    back
  }
}
</script>

<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  min-height: 25.6rem;
  box-sizing: border-box;
  .router-nav {
    width: 100%;
    height: 0.4rem;
    background-color: #ffffff;
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .router-name {
      font-size: 0.16rem;
      @include font_color('font-medium');
      margin-left: 0.1rem;
    }
  }

  .content {
    /*min-height: calc(100vh - 1.4rem);*/
    min-height: 24.8rem;
    padding: 0.4rem 0.4rem 0 0.4rem;
    box-sizing: border-box;
    // @include background_color("background-medium");

    .block {
      position: relative;
      cursor: pointer;

      .block-title {
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
        -webkit-line-clamp: 3; /* 控制最多显示几行 */
        -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
        position: absolute;
        box-sizing: border-box;
        @include background_color('background-normal');
        color: #ffffff;
        opacity: 0.7;
      }

      .block-title-1 {
        width: 6.93rem;
        padding: 0rem 0.56rem;
        top: 0.66rem;
        right: 0.5rem;
        font-size: 0.53rem;
      }

      .block-title-2,
      .block-title-3 {
        width: 4.26rem;
        padding: 0rem 0.3rem;
        bottom: 0.26rem;
        right: 0.26rem;
        font-size: 0.4rem;
      }

      .block-title-4,
      .block-title-5,
      .block-title-6,
      .block-title-7 {
        width: 2.14rem;
        padding: 0rem 0.25rem;
        bottom: 0.55rem;
        right: 0.25rem;
        font-size: 0.37rem;
      }

      .block-title-8,
      .block-title-9 {
        width: 4.27rem;
        padding: 0rem 0.25rem;
        bottom: 0.2rem;
        right: 0.2rem;
        font-size: 0.37rem;
      }

      .block-title-10,
      .block-title-11,
      .block-title-12 {
        width: 3.13rem;
        padding: 0rem 0.5rem;
        bottom: 0.38rem;
        right: 0.15rem;
        font-size: 0.37rem;
      }
    }

    img {
      width: 100%;
    }

    .content-block-top {
      &-right {
        &-top {
          display: flex;
          justify-content: space-between;
          margin-top: 0.4rem;

          .block {
          }
        }

        &-bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 0.4rem;

          .block {
          }
        }
      }
    }

    .content-block-bottom {
      /*display: flex;*/

      &-left {
        display: flex;
        justify-content: space-between;
        margin-top: 0.4rem;

        .block {
          /*width: 3.58rem;*/
          /*height: 3.4rem;*/
          /*margin-right: 0.58rem;*/
        }
      }

      &-right {
        display: flex;
        justify-content: space-between;
        margin-top: 0.4rem;

        .block {
          /*width: 5.64rem;*/
          /*height: 3.4rem;*/
          /*margin-right: 0.58rem;*/
        }
      }
    }

    .content-block-custom {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.4rem;

      .block {
        margin-right: 0.4rem;
        margin-bottom: 0.4rem;
      }

      .block:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
