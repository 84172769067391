<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" style="width: 0.16rem; height: 0.16rem" />
    </nav-router>
    <div class="tabs">
      <div :class="[
          'tab-item',
          activeIndex === index ? 'active-tab' : 'blur-item',
        ]" v-for="(item, index) in floorList" :key="index" @click="changeTabs(index, item.books)">
        <span>{{ item.name }}</span>
        <div class="bottom-border" v-show="activeIndex === index"></div>
      </div>
    </div>
    <div class="content">
      <div class="floor-item">
        <div v-for="(item, index) in Math.ceil(books.length / 3)" :key="index" style="margin-bottom: 0.8rem">
          <div class="floor-item-content">
            <div class="book-list">
              <div class="floor-item-icon" v-for="book in books.slice(index * 3, index * 3 + 3)" :key="book.id" @click="handleClick(book)">
                <img :src="`${OSS_URL}${book.pictureVertical}`" alt="" />
                <p class="book-name">{{ book.name }}</p>
              </div>
            </div>
          </div>
          <div class="floor-item-shelf">
            <img src="@/assets/images/dangHistory/shelf.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <back :pageDepth="2"></back>
  </div>
</template>

<script>
import navRouter from '@/components/navRouter'
import back from '@/components/back'

const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { openUrl } from '@/utils/windowOpen'
const blueBg = require('@/assets/images/index/bg-blue.png')
const redBg = require('@/assets/images/index/bg.png')
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PARTY_LECTURE_JOURNAL',
  props: {
    appid: {
      type: String,
      required: true
    }
  },
  components: {
    navRouter,
    back
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgRed: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgBlue: {
        backgroundImage: 'url(' + blueBg + ')'
      },
      activeIndex: 0,
      floorList: [],
      books: []
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.bg = this.bgRed
    } else {
      this.icon = blueIcon
      this.bg = this.bgBlue
    }
    this.getData()
    // 保存路径
    this.setRouterList([{ name: '党史馆', path: this.$route.fullPath }])
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
      }
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    changeTabs(index, books) {
      this.activeIndex = index
      this.books = books
    },
    handleClick(item) {
      if (item.externalLinksUrl) {
        openUrl(item.externalLinksUrl)
      } else {
        this.$router.push({ path: '/layout/read', query: { id: item.id } })
      }
    },
    async getData() {
      const res = await this.GET(this.API_MODULE.SEARCH_CATEGORY_OR_CONTENT, {
        appId: this.appid
      })

      const categorys = res.data.model
      for (const key in categorys) {
        const floor = {}
        floor.id = categorys[key].id
        floor.name = categorys[key].name

        const courseResult = await this.POST(this.API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
          catId: [categorys[key].id]
        })
        if (courseResult.code == 0) {
          floor.books = courseResult.data.model
          this.floorList.push(floor)
        } else {
          // this.$message({ message: courseResult.msg, type: 'error' })
        }
      }

      this.changeTabs(0, this.floorList[0].books)
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/themes/_handle.scss';
.box {
  min-height: 24.8rem;
  background-size: cover;
  background-repeat: no-repeat;
  .tabs {
    width: 100vw;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;

    @include background_color('background-medium');
    .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.6rem;
      text-align: center;
      line-height: 1rem;
      font-size: 0.3rem;
      color: #ffffff;
      border-radius: 0.05rem;
      flex: 1;
      position: relative;
      white-space: nowrap;
      margin-left: 1rem;

      .bottom-border {
        width: 0.82rem;
        height: 0.14rem;
        background-color: #ffffff;
        border-top-left-radius: 0.07rem;
        border-top-right-radius: 0.07rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .blur-item {
    }

    .active-tab {
      font-weight: 700;
    }
  }
  .content {
    padding-top: 0.8rem;
    padding-left: 0.6rem;
    padding-right: 0.7rem;

    .floor-item {
      margin-bottom: 0.8rem;

      &-content {
        display: flex;
        align-items: center;
        overflow: hidden;
        padding-left: 1.3rem;
        .book-list {
          /*width: 22.62rem;*/
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          overflow-x: scroll;
          overflow-y: hidden;
          flex-shrink: 0;
        }

        .floor-item-name {
          font-size: 0.72rem;
          font-weight: 400;
          color: #ffed0f;
          text-shadow: 0.08rem 0.1rem 0.02rem #b40000;
          opacity: 1;
          margin-left: 0.86rem;
          margin-right: 0.8rem;
          flex-shrink: 0;
        }

        .floor-item-icon {
          width: 3.1rem;
          height: 3.8rem;
          margin-right: 0.8rem;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          .book-name {
            position: absolute;
            left: 0.3rem;
            right: 0;
            bottom: 0.2rem;
            height: 0.5rem;
            line-height: 0.5rem;
            width: 2.5rem;
            background: #580000;
            color: #ffffff;
            font-size: 0.2rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      &-shelf {
        width: 13.6rem;

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
