<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div v-for="promise in promiseList" :key="promise.id" class="promise-item" @click="handleClick(promise)">
        <div class="promise-img">
          <img :src="`${OSS_URL}${promise.pictureVertical}`" alt="" />
        </div>
        <div class="promise-title">
          <span>{{ promise.name }}</span>
        </div>
      </div>
    </div>
    <back :pageDepth="2"></back>
  </div>
</template>

<script>
import navRouter from '@/components/navRouter'
import back from '@/components/back'

const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')

const blueBg = require('@/assets/images/base/bg-blue.png')
const redBg = require('@/assets/images/base/bg.png')
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'VOW_WALL',
  props: {
    appid: {
      type: String,
      required: true
    }
  },
  components: {
    navRouter,
    back
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgRed: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgBlue: {
        backgroundImage: 'url(' + blueBg + ')'
      },
      promiseList: []
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.bg = this.bgRed
    } else {
      this.icon = blueIcon
      this.bg = this.bgBlue
    }
    this.getData()

    // 保存路径
    this.setRouterList([
      {
        name: '宣誓墙',
        path: this.$route.fullPath
      }
    ])
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
      }
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getData() {
      this.GET(this.API_MODULE.SEARCH_CATEGORY_OR_CONTENT, { appId: this.appid })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.promiseList = res.data.model
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$$message(err.msg)
        })
    },
    handleClick(promise) {
      this.$router.push({
        path: '/layout/promiseInfo',
        query: { id: promise.id, music: promise.music }
      })

      const path = `/layout/promiseInfo?id=${promise.id}#music=${promise.music}`
      this.useRouterList.push({
        name: promise.name,
        path: path
      })
      this.setRouterList(this.useRouterList, path)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  height: 24.8rem;
  box-sizing: border-box;

  .content {
    padding: 0.4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .promise-item {
      width: 6.6rem;
      height: 8.66rem;
      position: relative;

      .promise-img {
        width: 6.6rem;
        height: 8.66rem;

        img {
          width: 6.6rem;
          height: 8.66rem;
        }
      }

      .promise-title {
        position: absolute;
        padding: 0 0.12rem;
        box-sizing: border-box;
        height: 1.2rem;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        line-height: 1.2rem;
        color: #ffffff;
        font-size: 0.48rem;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>

