<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="title">
        <span>智慧课堂</span>
      </div>
      <div class="sub-title">
        <span>课前微课导学、课堂互动探究、课后个性辅导</span>
      </div>
      <div class="subject-list">
        <div class="subject-item" v-for="item in subjectList" :key="item.id" @click="handleClick(item)">
          <div class="subject-cover">
            <img :src="`${OSS_URL}/${item.pictureVertical}`" alt="" />
          </div>
          <div class="subject-introduction">
            <span class="subject-name">{{ item.name }}</span>
            <span class="subject-total" v-if="item.code != 'MY_CLASS'">
              共{{ item.contentCount }}个
            </span>
          </div>
        </div>
      </div>
    </div>
    <menu-pop :activeItem="3"></menu-pop>
    <!-- <back :pageDepth="2"></back> -->

    <div class="back-group">
      <div class="btn-group">
        <div v-if="showMore" @click="$router.push({ path: '/layout/index' })">
          <img src="@/assets/images/index/back-gray.png" alt="" />
        </div>
        <div v-if="showMore" @click="showMore = !showMore">
          <img src="@/assets/images/index/close-gray.png" alt="" />
        </div>
        <div v-if="!showMore" @click="showMore = !showMore">
          <img src="@/assets/images/index/open-gray.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import navRouter from '@/components/navRouter'

const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
const blueBg = require('@/assets/images/index/bg-blue.png')
const redBg = require('@/assets/images/index/bg.png')
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'WISDOM_CLASSROOM',
  props: {
    appid: {
      type: String,
      required: true
    }
  },
  components: {
    menuPop,
    navRouter
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgRed: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgBlue: {
        backgroundImage: 'url(' + blueBg + ')'
      },
      subjectList: [],
      showMore: false
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.bg = this.bgRed
    } else {
      this.icon = blueIcon
      this.bg = this.bgBlue
    }
    this.getData()

    // 保存路径
    this.setRouterList([{ name: '智慧课堂', path: this.$route.fullPath }])
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
      }
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getData() {
      this.GET(this.API_MODULE.SEARCH_CATEGORY_OR_CONTENT, { appId: this.appid })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.subjectList = res.data.model
            // this.mineCourse = res.data.model.slice(2, 3)[0];
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    handleClick(item) {
      item.code === 'MY_CLASS'
        ? this.$router.push({
            path: '/layout/mine',
            query: {
              id: item.id
            }
          })
        : this.$router.push({
            path: '/layout/directory',
            query: {
              id: item.id
            }
          })
      const path = item.code === 'MY_CLASS' ? `/layout/mine?id=${item.id}` : `/layout/directory?id=${item.id}`
      this.useRouterList.push({
        name: item.name,
        path: path
      })
      this.setRouterList(this.useRouterList, path)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  min-height: 25.6rem;
  background-size: cover;
  background-repeat: no-repeat;
  .content {
    min-height: 24.2rem;
    padding: 0.4rem 0 0.4rem 0;
    box-sizing: border-box;
    // @include background_color("background-medium");

    .title {
      font-size: 0.74rem;
      line-height: 0.74rem;
      font-weight: 400;
      color: #ffffff;
      margin-top: 1.3rem;
      text-align: center;
      margin-bottom: 0.4rem;
    }

    .sub-title {
      font-size: 0.37rem;
      line-height: 0.37rem;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
    }

    .subject-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0 1rem;
      margin-top: 1.48rem;
      .subject-item {
        margin-left: 0.4rem;
        margin-bottom: 0.4rem;
        width: 5.6rem;
        height: 5.38rem;
        position: relative;

        .subject-cover {
          width: 5.62rem;
          height: 5.38rem;

          img {
            width: 5.6rem;
            height: 5.38rem;
          }
        }

        .subject-introduction {
          padding: 0 0.4rem;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1.6rem;
          background-color: rgba(204, 167, 102, 0.6);
          display: flex;
          align-items: center;
          justify-content: space-between;

          .subject-name {
            width: 3.6rem;
            overflow: hidden;
            /*文本不会换行*/
            white-space: nowrap;
            /*当文本溢出包含元素时，以省略号表示超出的文本*/
            text-overflow: ellipsis;
            font-size: 0.48rem;
            font-weight: 600;
            color: #ffffff;
            opacity: 1;
            text-align: left;
          }

          .subject-total {
            font-size: 0.36rem;
            color: #ffffff;
            opacity: 1;
          }
        }
      }
    }
  }
}

.back-group {
  z-index: 3005;
  position: fixed;
  right: 0.6rem;
  bottom: 0.8rem;
  /*opacity: 0.8;*/
  .btn-group {
    div {
      margin-bottom: 0.1rem;
    }
  }
  img {
    width: 1.2rem;
  }
}
</style>
