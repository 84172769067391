<template>
  <div class="box" :style="bg">
    <div class="router-nav">
      <div class="nav-left">
        <img :src="icon" alt="" />
        <p class="router-name">
          <span v-for="(item, index) in useRouterList" :key="index" :class="[index === 0 ? '' : 'sub-router']" @click="jumpTo(item.path)">
            {{ item.name }}
            <span v-if="index !== useRouterList.length - 1"> > </span>
          </span>
        </p>
      </div>
      <div class="nav-right">
        <div :class="['tag', activeTag === '0' ? 'active-tag' : '']" @click="selectTag('0')">
          全部
        </div>
        <div :class="['tag', item.id === activeTag ? 'active-tag' : '']" v-for="item in topMenus" :key="item.id" @click="selectTag(item.id)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="content">
      <div class="list">
        <div class="item" v-for="item in photoAlbumList" :key="item.id" @click="handleClick(item)">
          <div class="cover">
            <img :src="`${OSS_URL}/${item.pictureVertical}`" alt="" />
          </div>
          <div class="introduction">
            <span class="title">{{ item.name }}</span>
            <span class="total">共{{ item.contentCount }}张</span>
          </div>
        </div>
      </div>
    </div>
    <menu-pop :activeItem="2"></menu-pop>
    <back :pageDepth="2"></back>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import back from '@/components/back'

const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
const blueBg = require('@/assets/images/index/bg-blue.png')
const redBg = require('@/assets/images/index/bg.png')
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'PARTY_BUILDING_ELEGANT_DEMEANOUR',
  props: {
    appid: {
      type: String,
      required: true
    }
  },
  components: {
    menuPop,
    back
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgRed: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgBlue: {
        backgroundImage: 'url(' + blueBg + ')'
      },
      activeTag: '0',
      photoAlbumList: [],
      topMenus: [],
      ids: []
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.bg = this.bgRed
    } else {
      this.icon = blueIcon
      this.bg = this.bgBlue
    }

    this.getAlbumClassification(this.appid)

    // 保存路径
    this.setRouterList([
      {
        name: '党建风采',
        path: this.$route.fullPath
      }
    ])
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
      }
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    // 获取相册分类
    getAlbumClassification(appId) {
      this.GET(this.API_MODULE.SEARCH_CATEGORY_OR_CONTENT, {
        appId: appId
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.topMenus = res.data.model
            this.topMenus.forEach((menu) => {
              this.ids.push(menu.id)
            })
            this.getPhotoAlbumList(this.ids)
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    // 获取相册列表
    getPhotoAlbumList(ids) {
      this.POST(this.API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
        catId: ids
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.photoAlbumList = res.data.model
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    selectTag(id) {
      this.activeTag = id
      this.getPhotoAlbumList(id === '0' ? this.ids : [id])
    },
    handleClick(item) {
      this.$router.push({
        path: '/layout/photoList',
        query: { id: item.id, title: item.name, music: item.music }
      })

      const path = `/layout/photoList?id=${item.id}#title=${item.name}#music=${item.music}`
      this.useRouterList.push({
        name: item.name,
        path: path
      })
      this.setRouterList(this.useRouterList, path)
    },
    jumpTo(path) {
      if (!path) return false
      this.$router.push({
        path
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  min-height: 25.6rem;
  background-size: cover;
  background-repeat: no-repeat;
  .router-nav {
    width: 100%;
    height: 0.6rem;
    background-color: #ffffff;
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .nav-left {
      display: flex;
      flex: 1;
      .router-name {
        font-size: 0.16rem;
        @include font_color('font-medium');
        margin-left: 0.1rem;
      }
    }

    .nav-right {
      display: flex;
      justify-content: flex-end;
      flex: 2;
      overflow-y: auto;
      .tag {
        height: 0.3rem;
        line-height: 0.3rem;
        border-radius: 0.05rem;
        padding: 0 0.32rem;
        color: #cca766;
        font-size: 0.16rem;
        /*margin-right: 0.5rem;*/
        cursor: pointer;
        white-space: nowrap;
      }

      .active-tag {
        @include background_color('background-medium');
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .content {
    /*min-height: calc(100vh - 1.4rem);*/
    min-height: 24.2rem;
    padding: 0.4rem 0 0 0.4rem;
    box-sizing: border-box;
    // @include background_color("background-medium");

    .list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 6.6rem;
        height: 4.5rem;
        overflow: hidden;
        position: relative;
        margin-right: 0.4rem;
        margin-bottom: 0.4rem;
        cursor: pointer;

        .cover {
          height: 100%;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .introduction {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1.2rem;
          background-color: #cca766;
          opacity: 0.8;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0.4rem;

          .title {
            font-size: 0.36rem;
            font-weight: bold;
            color: #ffffff;
            opacity: 1;
          }

          .total {
            font-size: 0.22rem;
            font-weight: 400;
            color: #ffffff;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
